import { Button, Richtext } from '@/atoms'
import Image from 'next/image'
import { COMPONENTS_MAP_NAME } from 'utils/functions'

const LogosImageList = ({ items }) => {
  const { title, description, items: _items } = items

  const hasItems = _items && _items.length > 0
  return (
    <section className={'logos-img-list_section-wrapper'}>
      <div className={'logos-img-list_section-wrapper_paragraph'}>
        <h2 className={'logos-img-list_section-wrapper_paragraph_title'}>
          {title}
        </h2>
        <Richtext
          className={'logos-img-list_section-wrapper_paragraph_description '}
          textAlign={'left'}
        >
          {description}
        </Richtext>
      </div>
      {hasItems ? (
        <div className={'logos-img-list_section-wrapper_image-list'}>
          {_items
            ? _items?.map((item, index) => (
                <LogoWithText
                  items={item}
                  key={`logos-img-list-${index}`}
                />
              ))
            : null}
        </div>
      ) : null}
    </section>
  )
}

const LogoWithText = ({ items }) => {
  const { image, title, description, link } = items
  return (
    <div className={'logos-img-list_section-wrapper_image-list_item_wrapper'}>
      <div
        className={'logos-img-list_section-wrapper_image-list_item_img-wrapper'}
      >
        <Image
          className={
            'logos-img-list_section-wrapper_image-list_item_img-wrapper_img'
          }
          src={image.url}
          fill
          alt={image.alt}
        />
      </div>
      <div
        className={
          'logos-img-list_section-wrapper_image-list_item_text-wrapper'
        }
      >
        <h3
          className={
            'logos-img-list_section-wrapper_image-list_item_text-wrapper_title'
          }
        >
          {title}
        </h3>
        <Richtext
          className={
            'logos-img-list_section-wrapper_image-list_item_text-wrapper_description'
          }
          textAlign={'left'}
        >
          {description}
        </Richtext>
        <Button
          className={
            'logos-img-list_section-wrapper_image-list_item_text-wrapper_cta'
          }
          variant={'link'}
          href={link.url}
          openInNewTab={link.target === '_blank'}
          trackEventArgs={{
            category: 'Button',
            action: 'Click',
            name: COMPONENTS_MAP_NAME.LogosImageList,
            value: link.title,
          }}
        >
          {link.title}
        </Button>
      </div>
    </div>
  )
}

export default LogosImageList
