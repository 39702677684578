import { Breadcrumbs, Button, Richtext } from '@/atoms'
import { PixelDecorator } from '@/molecules'
import { Col, Container, Hero, HeroTitle } from 'design-react-kit'
import { useMediaQuery } from 'hooks'
import Image from 'next/image'
import { COMPONENTS_MAP_NAME } from 'utils/functions'

const SideImageHero = ({ items, isHero }) => {
  const {
    title,
    description,
    hasCta = true,
    cta,
    useParallax = false,
    objectFit: imageObjectFit = 'cover',
    pixelDecorator = 'NONE',
    customInset = null,
  } = items
  const isDesktop = useMediaQuery('lg')
  const descriptionDisplayClass = isDesktop ? 'display-4' : 'display-5'
  const pixelDecoratorInsetOffset = {
    A: '0,0,0,0',
    B: '0,0,0,0',
    C: '0,0,0,0',
  }
  return (
    <section className={'side-image-hero_section'}>
      <Hero
        className={'side-image-hero_wrapper'}
        overlay={'dark'}
      >
        <Container
          fluid
          className={'side-image-hero_container-fluid'}
        >
          <div className={'side-image-hero_content-wrapper'}>
            <Col className={'side-image-hero_text-column'}>
              <HeroTitle className={'side-image-hero_title display-1'}>
                {title}
              </HeroTitle>
              <h2
                className={`side-image-hero_description ${descriptionDisplayClass}`}
              >
                <Richtext textAlign={'left'}>{description}</Richtext>
              </h2>
              {hasCta ? (
                <Button
                  href={cta?.url}
                  fullWidth={false}
                  internalUrl={cta?.internal_url}
                  isInternalPage={cta?.internal_page}
                  openInNewTab={cta?.open_link_in_new_tab}
                  className={'side-image-hero_button btn-primary'}
                  trackEventArgs={{
                    category: 'Button',
                    action: 'Click',
                    name: COMPONENTS_MAP_NAME.SideImageHero,
                    value: cta.label,
                  }}
                >
                  {cta.label}
                </Button>
              ) : null}
            </Col>
            <Col className={'side-image-hero_img-column'}>
              {/* <ThreeRectangles className={'side-image-hero_three-svg'} /> */}
              <div className='img-responsive-wrapper'>
                <div className='img-responsive'>
                  <div className='side-image-hero_img-wrapper img-wrapper'>
                    <PixelDecorator
                      pixelDecorator={pixelDecorator}
                      insetOffset={pixelDecoratorInsetOffset}
                      inset={customInset}
                    >
                      {useParallax ? (
                        <div
                          className={'side-image-hero_img-wrapper parallax'}
                          style={{
                            backgroundImage: `url(${items.image})`,
                          }}
                        />
                      ) : (
                        <Image
                          src={items.image}
                          alt={'Immagine hero laterale'}
                          className={'side-image-hero_img '}
                          fill
                          style={{ objectFit: imageObjectFit }}
                        />
                      )}
                    </PixelDecorator>
                  </div>
                </div>
              </div>
            </Col>
            {isHero ? (
              <Breadcrumbs className='side-image-hero_content-wrapper_breadcrumbs' />
            ) : null}
          </div>
        </Container>
      </Hero>
    </section>
  )
}

export default SideImageHero
