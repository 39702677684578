import { Button, Richtext } from '@/atoms'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Timeline as TimelineDesignReactKit,
  TimelinePin,
} from 'design-react-kit'
import { useMediaQuery } from 'hooks'
import Image from 'next/image'
import {
  COMPONENTS_MAP_NAME,
  handleCustomBGDataAttribute,
} from 'utils/functions'
import { ArrowRight } from '../Svgs'

const mainClassName = 'timeline'
const Timeline = ({ items }) => {
  const {
    title,
    description,
    customBackgroundColor = false,
    items: timelineItems = [],
  } = items
  const hasText = title !== '' || description !== ''
  const hasTimelineItems = timelineItems && timelineItems.length > 0

  if (!hasText && !hasTimelineItems) return null
  return (
    <section
      className={`${mainClassName + '_section'}`}
      style={handleCustomBGDataAttribute(customBackgroundColor)}
    >
      <div className={`${mainClassName + '_section_container'}`}>
        {hasText ? (
          <div className={`${mainClassName + '_section_container_text'}`}>
            <h2
              className={`${mainClassName + '_section_container_text_title'}`}
            >
              {title}
            </h2>
            <Richtext
              className={`${
                mainClassName + '_section_container_text_description'
              }`}
            >
              {description}
            </Richtext>
          </div>
        ) : null}
        {hasTimelineItems ? (
          <TimelineDesignReactKit
            className={`${mainClassName + '_section_container_timeline'}`}
          >
            <Row>
              {timelineItems.map((item, index) => (
                <TimelineItem
                  key={`timeline-item-${index}`}
                  item={item}
                />
              ))}
            </Row>
          </TimelineDesignReactKit>
        ) : null}
      </div>
    </section>
  )
}

const itemClassName = 'timeline-item'
const nowText = 'Oggi'

const TimelineItem = ({ item }) => {
  const { title, description, date, cta, image, order, isToday, isPast } = item
  const isDesktop = useMediaQuery('lg')

  const isLeftSide = order % 2 === 0
  const hasCta = !!cta && cta.label !== ''
  const hasImage = !!image && image !== ''

  const descriptionAlign = () => {
    if (isDesktop) {
      return isLeftSide ? 'left' : 'right'
    } else {
      return 'left'
    }
  }

  return (
    <Col xs='12'>
      <TimelinePin
        label={date}
        nowText={nowText}
        past={isPast}
        now={isToday}
      >
        <Card className={`${itemClassName + '_card'}`}>
          <CardBody
            id={'body'}
            className={`${itemClassName + '_card_body'}`}
            data-align={isLeftSide ? 'left' : 'right'}
          >
            <CardTitle
              id={'title'}
              className={`${itemClassName + '_card_body_title'}`}
              tag='h5'
              data-align={isLeftSide ? 'left' : 'right'}
            >
              {title}
            </CardTitle>
            {hasImage ? (
              <div className={`${itemClassName + '_card_body_image'}`}>
                <Image
                  fill
                  src={image}
                  alt={title}
                />
              </div>
            ) : null}
            <Richtext
              className={`${itemClassName + '_card_body_description'}`}
              isDefault={false}
              textAlign={descriptionAlign()}
            >
              {description}
            </Richtext>
            {hasCta ? (
              <Button
                id={'button'}
                variant='link'
                href={cta?.url}
                internalUrl={cta?.internal_url}
                isInternalPage={cta?.internal_page}
                openInNewTab={cta?.open_link_in_new_tab}
                className={`${itemClassName + '_card_body_button'}`}
                trackEventArgs={{
                  category: 'Button',
                  action: 'Click',
                  name: COMPONENTS_MAP_NAME.Timeline,
                  value: cta.label,
                }}
              >
                {cta.label.toUpperCase() + ' '}
                <ArrowRight />
              </Button>
            ) : null}
          </CardBody>
        </Card>
      </TimelinePin>
    </Col>
  )
}

export default Timeline
