import { ArrowRight } from '@/molecules/Svgs'
import { useIntersectionObserver } from 'hooks'
import { useRouter } from 'next/router'
import { COMPONENTS_MAP_NAME } from 'utils/functions'
import Button from '../Button'
import Richtext from '../Richtext'

const mainClassName = 'learn-more-card'

const LearnMoreCard = ({
  number,
  title,
  text,
  items: { cta },
  cardOrder = 0,
}) => {
  const isValidUrl = cta?.url && cta?.url !== '#'
  const router = useRouter()
  const { ref: cardRef, isIntersecting: isCardVisible } =
    useIntersectionObserver({
      freezeOnceVisible: false,
    })

  const slideBottomUpClassName =
    cardOrder === 0
      ? 'slide-bottom-up fade-in-4  visible'
      : `slide-bottom-up-${cardOrder} fade-in-4`

  const handleCardClick = (e) => {
    e.preventDefault()
    isValidUrl ? router.push(cta?.url) : null
  }
  return (
    <section
      ref={cardRef}
      className={`${mainClassName + '_section'} shadow ${
        isCardVisible ? slideBottomUpClassName : 'fade-out-4 invisible'
      }`}
      onClick={handleCardClick}
    >
      <div className={`${mainClassName + '_section_container'}`}>
        <div className={`${mainClassName + '_section_container_number'}`}>
          <span>{number}</span>
        </div>
        <div className={`${mainClassName + '_section_container_title'}`}>
          <h3>{title}</h3>
        </div>
        {text !== '' ? (
          <Richtext
            textAlign={'left'}
            className={`${mainClassName + '_section_container_text'}`}
          >
            {text}
          </Richtext>
        ) : null}
        <Button
          href={cta?.url}
          variant={'link'}
          internalUrl={cta?.internal_url}
          isInternalPage={cta?.internal_page}
          openInNewTab={cta?.open_link_in_new_tab}
          className={`${mainClassName + '_section_container_cta'}`}
          isDisabled={!isValidUrl}
          trackEventArgs={{
            category: 'Button',
            action: 'Click',
            name: `Card ${COMPONENTS_MAP_NAME.ContentCardsList}`,
            value: cta.label,
          }}
        >
          {cta.label}
          <ArrowRight />
        </Button>
      </div>
    </section>
  )
}

export default LearnMoreCard
