import {
  NavItem as BaseNavItem,
  Collapse,
  Header,
  HeaderContent,
  HeaderSearch,
  HeaderToggler,
  Icon,
  Nav,
} from 'design-react-kit'
import Image from 'next/image'
import Link from 'next/link'

import { useEffect, useState } from 'react'
import { disableScroll, trackCustomEvent, withNextLink } from 'utils/functions'

const getActiveItemId = (items, homepage, slug) => {
  const activeItem = items?.find((item) => item.url === slug + '/')
  if (slug !== '/' && activeItem === -1) return null
  return activeItem ? activeItem?.id : homepage?.id
}

const searchTrackIconEvent = {
  category: 'Link',
  action: 'Click',
  name: 'Icona Ricerca',
  value: '/ricerca',
}

const NavHeader = ({ navItems, homepage, currentPath, isHeaderVisible }) => {
  const [mounted, setMounted] = useState(false)
  const [activeItem, setActiveItem] = useState(null)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const handleMenuToggle = () => setIsMobileMenuOpen(!isMobileMenuOpen)
  const handleItemClick = (itemIndex) => {
    const selectedItem = navItems.find((item) => item.id === itemIndex)
    trackCustomEvent({
      category: 'Header',
      action: 'Click',
      name: selectedItem.title,
      value: selectedItem.url,
    })
    setActiveItem(itemIndex)
  }

  useEffect(() => {
    const activeItem = getActiveItemId(navItems, homepage, currentPath)
    setActiveItem(activeItem)
    setMounted(true)
  }, [])

  useEffect(() => {
    disableScroll(isMobileMenuOpen)
  }, [isMobileMenuOpen])

  if (!mounted) return null

  return (
    <>
      <Header
        theme=''
        type='navbar'
        className={`${!isHeaderVisible ? 'it-header-sticky' : ''}`}
      >
        <HeaderContent
          expand='lg'
          megamenu
        >
          <HeaderToggler
            aria-controls='nav1'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={handleMenuToggle}
          >
            <Icon icon='it-burger' />
          </HeaderToggler>
          <Collapse
            header
            navbar
            onOverlayClick={handleMenuToggle}
            isOpen={isMobileMenuOpen}
          >
            <div className='menu-wrapper'>
              <Nav navbar>
                {!isHeaderVisible ? (
                  <div className={'menu-wrapper_logo'}>
                    <Link href='/'>
                      <Image
                        src={'/logo-dl-long.png'}
                        className={'logo'}
                        alt={'Logo'}
                        width={160}
                        height={60}
                        style={{
                          objectFit: 'contain',
                        }}
                      />
                    </Link>
                  </div>
                ) : null}
                {navItems?.map((item, index) => (
                  <NavItem
                    key={`nav-link-${index}`}
                    {...item}
                    onClick={handleItemClick}
                    isActive={activeItem === item.id}
                  />
                ))}
                {!isHeaderVisible
                  ? withNextLink(
                      <HeaderSearch
                        href={'/ricerca'}
                        aria-label={'Cerca'}
                        iconName={'it-search'}
                        label={''}
                        style={{
                          cursor: 'pointer',
                          marginBlock: 'auto',
                          display: 'flex',
                        }}
                      />,
                      '/ricerca',
                    )
                  : null}
              </Nav>
              {isMobileMenuOpen ? (
                <Icon
                  className={'sidebar-icon-close'}
                  icon={'it-close-big'}
                  onClick={handleMenuToggle}
                />
              ) : null}
            </div>
          </Collapse>
        </HeaderContent>
      </Header>
    </>
  )
}

const NavItem = ({
  title = 'Link',
  url = '#',
  isActive = false,
  isDisabled = false,
  onClick = () => {},
  id,
}) => {
  const handleClick = () => onClick(id)
  return (
    <BaseNavItem
      active={isActive}
      onClick={handleClick}
    >
      <Link
        href={url}
        className={`nav-link ${isActive ? 'active' : ''}`}
        shallow={false}
      >
        {title}
      </Link>
    </BaseNavItem>
  )
}
export default NavHeader
