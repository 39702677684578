import { push } from '@socialgouv/matomo-next'
import Link from 'next/link'

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const capitalizeSentence = (string) => {
  const words = string.split(' ')
  return words.map((word) => capitalize(word)).join(' ')
}

export const getReadableDate = (date) => {
  const [day, month, year] = date.split('/')
  const _date = new Date(year, month - 1, day)
  const options = { day: 'numeric', month: 'long', year: 'numeric' }
  return _date.toLocaleDateString('it-IT', options)
}

export const disableScroll = (isDisabled) => {
  document.querySelector('html').style.overflow = isDisabled ? 'hidden' : 'auto'
}

export const formatMapUrl = (mapString) => {
  let response = mapString

  if (mapString.includes('<')) {
    const regex = /<iframe.*?src=["'](.*?)["']/
    const match = mapString.match(regex)

    if (match && match[1]) {
      response = match[1]
    }
  }

  return `${response}&zoom=16`
}

export const withNextLink = (children, href, customStyle, linkProps) => {
  const isLegacy = typeof children !== 'string'
  if (!href) return children
  const handleClickCapture = () => {
    if (linkProps?.trackEvent) {
      trackCustomEvent(linkProps.trackEvent)
    }
  }
  return (
    <Link
      href={href}
      passHref
      legacyBehavior={isLegacy}
      style={{
        textDecoration: 'inherit',
        cursor: 'pointer',
        ...customStyle,
      }}
      {...linkProps}
      onClickCapture={handleClickCapture}
    >
      {children}
    </Link>
  )
}

export const countCharsInHtmlString = (htmlString = null) => {
  if (!htmlString) return 0
  const text = htmlString.replace(/<[^>]*>?/gm, '')
  return text.length
}

export const isHexColorString = (color) => {
  return /^#([0-9A-F]{3}|[0-9A-F]{6})$/i.test(color)
}

export const handleCustomBGDataAttribute = (customBackgroundColor) => {
  return isHexColorString(customBackgroundColor)
    ? { backgroundColor: `${customBackgroundColor} !important` }
    : { backgroundColor: '#E3DECE !important' }
}

/**
 * @typedef {Object} TrackEventArgs
 * @property {string} category - Categoria dell'evento
 * @property {string} action - Azione dell'evento
 * @property {string} [name] - Nome opzionale dell'evento
 * @property {string|number} [value] - Valore opzionale dell'evento
 */

export const trackCustomEvent = (args) => {
  try {
    if (!args || typeof args !== 'object') {
      throw new Error('Invalid arguments: must be an object')
    }

    const { category, action, name = '', value = '' } = args

    // Validazione parametri obbligatori
    if (!category || typeof category !== 'string') {
      throw new Error('Invalid category: must be a non-empty string')
    }
    if (!action || typeof action !== 'string') {
      throw new Error('Invalid action: must be a non-empty string')
    }

    // Normalizzazione parametri opzionali
    const normalizedName = String(name).trim()
    const normalizedValue = String(value).trim()

    if (!normalizedName || !normalizedValue) {
      push(['trackEvent', category, action])
      console.info('[📈 Event tracked]', { category, action })
      return
    }

    push(['trackEvent', category, action, normalizedName, normalizedValue])
    console.info('[📈 Event tracked]', {
      category,
      action,
      name: normalizedName,
      value: normalizedValue,
    })
  } catch (error) {
    console.error('[📈 Error tracking event]', {
      error: error.message,
      args,
    })
    // Non rilanciare l'errore per evitare crash dell'applicazione
  }
}

export const COMPONENTS_MAP_NAME = {
  AreaSearch: 'Sezione ricerca',
  AvatarImageList: 'Paragrafo con elenco di gruppi di foto profilo',
  BigParagraph: 'Paragrafo con navigazione',
  Blockquote: 'Secione lista di citazioni',
  BottomImageHero: 'Testata principale con immagine in basso',
  CardSlider: 'Sezione carosello di carte',
  ContactsHero: 'Testata per pagina di contatti',
  ContactsWithMap: 'Paragrafo con informazioni di contatto e mappa',
  ContentCardsList: 'Paragrafo con lista di sezioni',
  ContentParagraphList: 'Paragrafo con lista di elementi',
  DarkParagraphWithImage: 'Paragrafo con fondo nero e immagine a destra',
  DynamicCardSlider: 'Sezione carosello di carte dinamico',
  FAQ: 'Sezione domande frequenti',
  FullImageParagraph: 'Paragrafo con immagine in basso',
  FullOverlayHero:
    'Testata principale con immagine di fonso e data di pubblicazione',
  FullVideo: 'Sezione con solo video',
  GenericSearch: 'Sezione ricerca',
  LogosImageList: 'Paragrafo con elenco di loghi',
  MainHero: 'Testata principale con immagine',
  ParagraphCard: 'Paragrafo con elenco di carte',
  ParagraphWithImage: 'Paragrafo con immagine a destra',
  SideImageHero: 'Testata principale con immagine e testo allineabili',
  SmallParagraph: 'Paragrafo semplice',
  TabText: 'Paragrafo con schede in basso',
  Timeline: 'Sezione linea temporale',
}
