import * as Atoms from '@/atoms'
import { Button, Chip, Dropdown, Input, Richtext } from '@/atoms'
import Pager from '@/atoms/Pager'
import { Spinner } from 'design-react-kit'
import { AnimatePresence, motion } from 'framer-motion'
import { useMediaQuery } from 'hooks'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { COMPONENTS_MAP_NAME } from 'utils/functions'

const mainClassName = 'search-grid'

const MOBILE_PAGE_SIZE = 3
const DESKTOP_PAGE_SIZE = 6
const ORDER_BY = {
  desc: {
    name: 'Discedente',
    value: 'desc',
  },
  asc: {
    name: 'Ascedente',
    value: 'asc',
  },
}

const handlePrefilledFilters = (queryValue, allValues) => {
  if (!queryValue) return []
  const prefilledValueParsed = queryValue.includes(',')
    ? queryValue
        .split(',')
        .map((item) => Number(item))
        .filter(
          (item) =>
            item !== NaN && allValues.find((category) => category.id === item),
        )
    : [Number(queryValue)]
  return Array.from(new Set(prefilledValueParsed))
}

const SearchGrid = (props) => {
  const {
    title = 'Titolo',
    description = 'Descrizione',
    ctaCardLabel = 'Scopri di più',
    ctaSearchLabel = 'Avvia ricerca',
    cardType = 'LearnMoreCard',
    searchQueryValue: _searchQueryValue = '',
    items: cards = [],
    categories = [],
    categoriesLabel = 'Filtra per',
    tags = [],
    tagsLabel = 'Filtra per',
    orderFilterLabel = 'Per ordine',
    areFiltersEnabled = false,
    isOrderFilterEnabled = false,
    areResoultsShownOnMount = false,
    isResultsContainerRow = false,
    isCategoriesFilterEnabled = false,
    isTagFilterEnabled = false,
    isLoading = false,
    pageSize = DESKTOP_PAGE_SIZE,
    mobilePageSize = MOBILE_PAGE_SIZE,
    categoryQueryParam = 'category',
    tagQueryParam = 'tags',
  } = props
  const router = useRouter()
  const queryParams = useSearchParams()
  const categoryQueryValue = queryParams.get(categoryQueryParam) ?? false
  const tagsQueryValue = queryParams.get(tagQueryParam) ?? false
  const isDesktop = useMediaQuery('md')
  const [searchValue, setSearchValue] = useState(_searchQueryValue)
  const [searchQueryValue, setSearchQueryValue] = useState(_searchQueryValue)
  const [currentPage, setCurrentPage] = useState(1)
  const [showResults, setShowResults] = useState(areResoultsShownOnMount)
  const [selectedFilters, setSelectedFilters] = useState({
    tagsFilters: handlePrefilledFilters(tagsQueryValue, tags),
    categoriesFilters: handlePrefilledFilters(categoryQueryValue, categories),
  })
  const [order, setOrder] = useState(() => ORDER_BY['desc'])
  const filtersAreActive =
    selectedFilters.tagsFilters?.length > 0 ||
    selectedFilters.categoriesFilters?.length > 0
  const CardComponent = Atoms[cardType]

  const PAGE_SIZE = isDesktop ? pageSize : mobilePageSize

  const numberedCards = useMemo(() => {
    if (!cards || !cards.length) return []
    if (!isTagFilterEnabled) {
      return cards
        .filter(({ categoryId }) => {
          return filtersAreActive
            ? selectedFilters.categoriesFilters.includes(categoryId)
            : true
        })
        .map((card, index) => ({
          ...card,
          number: `${index + 1}.`,
        }))
    }
    return cards
      .filter(({ categoryId, tagId }) => {
        return filtersAreActive
          ? selectedFilters.categoriesFilters.includes(categoryId) ||
              selectedFilters.tagsFilters.includes(tagId)
          : true
      })
      .map((card, index) => ({
        ...card,
        number: `${index + 1}.`,
      }))
  }, [cards, filtersAreActive, selectedFilters])

  const searchResultsLabel = useMemo(
    () =>
      searchQueryValue === ''
        ? `Risultati di ricerca (${numberedCards.length})`
        : `Risultati di ricerca per "${searchValue}" (${numberedCards.length})`,
    [searchQueryValue, numberedCards.length],
  )

  const currentCards = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE
    const lastPageIndex = firstPageIndex + PAGE_SIZE
    return numberedCards.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, PAGE_SIZE, isDesktop, cards, selectedFilters])

  const handlePageChange = (page) => {
    router.push('#search-title')
    setCurrentPage(page)
  }

  const handleSearch = async (e) => {
    e.preventDefault()
    router.query = { ...router.query, search: searchValue.toLowerCase() }

    router.push(router, undefined, { shallow: true })
    // router.push(
    //   {
    //     pathname: router.pathname,
    //     query: { ...router.query, search: searchValue.toLowerCase() },
    //   },
    //   undefined,
    //   { shallow: true },
    // )
    setSearchQueryValue(searchValue)
    setShowResults(true)
  }

  const handleFilterClick = ({ id, name, value = null, type = 'CATEGORY' }) => {
    const isTag = type === 'TAG'
    const { tagsFilters, categoriesFilters } = selectedFilters

    if (name === ORDER_BY.asc.name || name === ORDER_BY.desc.name) {
      setOrder(ORDER_BY[value])
      router.query = { ...router.query, order: value }
      router.push(router, undefined, { shallow: true })
      return
    }

    if (isTag) {
      if (tagsFilters?.includes(id)) {
        const newTagsFilters = tagsFilters.filter((item) => item !== id)
        router.query = { ...router.query, tags: newTagsFilters.join(',') }
        router.push(router, undefined, { shallow: true })
        setSelectedFilters({
          tagsFilters: newTagsFilters,
          categoriesFilters,
        })
        return
      }
      router.query = { ...router.query, tags: [...tagsFilters, id].join(',') }
      router.push(router, undefined, { shallow: true })
      setSelectedFilters({
        tagsFilters: [...tagsFilters, id],
        categoriesFilters,
      })
      return
    }

    if (categoriesFilters.includes(id)) {
      const newCategoriesFilters = categoriesFilters.filter(
        (item) => item !== id,
      )
      router.query = {
        ...router.query,
        [categoryQueryParam]: newCategoriesFilters.join(','),
      }
      router.push(router, undefined, { shallow: true })
      setSelectedFilters({
        tagsFilters,
        categoriesFilters: newCategoriesFilters,
      })
      return
    }

    router.query = {
      ...router.query,
      [categoryQueryParam]: [...categoriesFilters, id].join(','),
    }
    router.push(router, undefined, { shallow: true })
    setSelectedFilters({
      tagsFilters,
      categoriesFilters: [...categoriesFilters, id],
    })
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [searchValue, filtersAreActive, selectedFilters, isDesktop])

  return (
    <section className={`${mainClassName + '_section'}`}>
      <div className={`${mainClassName + '_section_container'}`}>
        <div className={`${mainClassName + '_section_container_paragraph'}`}>
          <h2
            id={'search-title'}
            className={`${
              mainClassName + '_section_container_paragraph_title'
            }`}
          >
            {title}
          </h2>
          <Richtext
            textAlign={'left'}
            className={`${
              mainClassName + '_section_container_paragraph_description'
            }`}
          >
            {description}
          </Richtext>
        </div>
        <div className={`${mainClassName + '_section_container_search'}`}>
          <Input
            className={`${mainClassName + '_section_container_search_input'}`}
            value={searchValue}
            onChange={setSearchValue}
            onKeyPress={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
          />
          <Button
            className={`${mainClassName + '_section_container_search_button'}`}
            variant='primary'
            onClick={handleSearch}
            trackEventArgs={{
              category: 'Button',
              action: 'Click',
              name: COMPONENTS_MAP_NAME.AreaSearch,
              value: `${ctaSearchLabel}-> ${searchValue}`,
            }}
          >
            {ctaSearchLabel}
          </Button>
        </div>
        {areFiltersEnabled && showResults ? (
          <AnimatePresence>
            <div
              className={`${
                mainClassName + '_section_container_filter-wrapper'
              }`}
            >
              {!isDesktop ? (
                <div
                  className={`${
                    mainClassName +
                    '_section_container_filter-wrapper_filter-label'
                  }`}
                >
                  {'Filtra per:'}
                </div>
              ) : null}
              <div
                className={`${
                  mainClassName + '_section_container_filter-wrapper_filters'
                }`}
              >
                {isCategoriesFilterEnabled ? (
                  <Dropdown
                    variant={'outline-dark'}
                    icons={['it-plus', 'it-minus']}
                    linkListItems={categories.map((item) => ({
                      ...item,
                      isActive: selectedFilters.categoriesFilters.includes(
                        item.id,
                      ),
                      onClick: () => handleFilterClick(item),
                    }))}
                    label={categoriesLabel}
                  />
                ) : null}
                {isTagFilterEnabled ? (
                  <Dropdown
                    variant={'outline-dark'}
                    icons={['it-plus', 'it-minus']}
                    linkListItems={tags.map((item) => ({
                      ...item,
                      isActive: selectedFilters.tagsFilters.includes(item.id),
                      onClick: () =>
                        handleFilterClick({ ...item, type: 'TAG' }),
                    }))}
                    label={tagsLabel}
                  />
                ) : null}
                {isOrderFilterEnabled ? (
                  <Dropdown
                    variant={'outline-dark'}
                    icons={['it-check']}
                    linkListItems={Object.values(ORDER_BY).map((item) => ({
                      ...item,
                      isActive: order.value === item.value,
                      onClick: () => handleFilterClick(item),
                    }))}
                    label={orderFilterLabel}
                  />
                ) : null}
              </div>
              {!isDesktop && selectedFilters.length > 0 ? (
                <AnimatePresence mode={'wait'}>
                  <div
                    className={`${
                      mainClassName +
                      '_section_container_filter-wrapper_filter-label'
                    }`}
                  >
                    {'Filtri attivi:'}
                  </div>
                </AnimatePresence>
              ) : null}
              <div
                className={`${
                  mainClassName + '_section_container_filter-wrapper_chips'
                }`}
              >
                {selectedFilters.tagsFilters?.map((filterId, index) => (
                  <motion.div
                    key={`${filterId}-${index}}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.2,
                    }}
                  >
                    <Chip
                      key={`${filterId}-${index}}`}
                      label={tags?.find((item) => item.id === filterId)?.name}
                      onDelete={() =>
                        handleFilterClick({ id: filterId, type: 'TAG' })
                      }
                    />
                  </motion.div>
                ))}
                {selectedFilters.categoriesFilters?.map((filterId, index) => (
                  <motion.div
                    key={`${filterId}-${index}}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.2,
                    }}
                  >
                    <Chip
                      key={`${filterId}-${index}}`}
                      label={
                        categories?.find((item) => item.id === filterId)?.name
                      }
                      onDelete={() => handleFilterClick({ id: filterId })}
                    />
                  </motion.div>
                ))}
              </div>
            </div>
          </AnimatePresence>
        ) : null}
        {showResults ? (
          <>
            <h3
              id={'search-results'}
              className={`${
                mainClassName + '_section_container_results_title'
              }`}
            >
              {searchResultsLabel}
            </h3>
            {cards.length > 0 ? (
              isLoading ? (
                <div
                  className={'col-12'}
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    margin: '2rem 0',
                  }}
                >
                  <Spinner
                    active
                    double
                  />
                </div>
              ) : (
                <ul
                  className={`${mainClassName + '_section_container_results'}`}
                  data-flex-direction={isResultsContainerRow ? 'row' : 'column'}
                  data-results-per-page={currentCards.length}
                >
                  <AnimatePresence mode={'popLayout'}>
                    {currentCards.length > 0
                      ? currentCards.map((card, index) => (
                          <motion.li
                            initial={{ opacity: 0, y: 80 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: index * 0.06 }}
                            key={`${card.number}-${card.title}`}
                            style={{ listStyle: 'none' }}
                          >
                            <CardComponent {...card} />
                          </motion.li>
                        ))
                      : null}
                  </AnimatePresence>
                </ul>
              )
            ) : null}
            <div
              className={`${mainClassName + '_section_container_pagination'}`}
            >
              <Pager
                currentPage={currentPage}
                totalCount={numberedCards.length}
                pageSize={isDesktop ? pageSize : mobilePageSize}
                onPageChange={handlePageChange}
              />
            </div>
          </>
        ) : null}
      </div>
    </section>
  )
}

export default SearchGrid
