import { Button, DynamicCard, Richtext, Slider } from '@/atoms'
import { useRouter } from 'next/router'
import { COMPONENTS_MAP_NAME } from 'utils/functions'

const mainClassName = 'news-slider'

const sliderBreakpoints = {
  0: {
    slidesPerView: 1,
    spaceBetween: 16,
  },
  350: {
    slidesPerView: 1.25,
    spaceBetween: 16,
  },
  450: {
    slidesPerView: 1.5,
    spaceBetween: 16,
  },
  576: {
    slidesPerView: 2,
    spaceBetween: 16,
  },
  850: {
    slidesPerView: 2,
    spaceBetween: 16,
  },
  1250: {
    slidesPerView: 3,
    spaceBetween: 16,
  },
}

const DynamicCardsSlider = ({ ...items }) => {
  const router = useRouter()
  const {
    title,
    description,
    cta,
    data,
    showDate,
    showimage: showImage,
    backgroundColor = 'light',
  } = items

  const isDark = backgroundColor === 'dark'
  const filteredData = data?.filter((item) => {
    const itemUrl = item.cta.url?.startsWith('/')
      ? item.cta.url
      : `/${item.cta.url}`
    const routerPath = router.asPath.startsWith('/')
      ? router.asPath
      : `/${router.asPath}`
    return routerPath !== itemUrl
  })
  const sliderItems =
    filteredData?.map((item, index) => {
      return (
        <DynamicCard
          key={`dynamic-card-${index}`}
          showDate={showDate}
          showImage={showImage}
          {...item}
        />
      )
    }) || []

  return (
    <section
      className={`${mainClassName + '_section'} ${isDark ? 'dark' : 'light'}`}
    >
      <div className={`${mainClassName + '_section_container'}`}>
        <div className={`${mainClassName + '_section_container_paragraph'}`}>
          <h2
            className={`${
              mainClassName + '_section_container_paragraph_title'
            }`}
          >
            {title}
          </h2>

          <Richtext
            className={`${
              mainClassName + '_section_container_paragraph_description'
            }`}
          >
            {description}
          </Richtext>
        </div>

        <div className={`${mainClassName + '_section_container_slider'}`}>
          <Slider
            breakpoints={sliderBreakpoints}
            variant={isDark ? 'dark' : 'light'}
          >
            {sliderItems}
          </Slider>
        </div>

        <Button
          href={cta?.url}
          internalUrl={cta?.internal_url}
          isInternalPage={cta?.internal_page}
          openInNewTab={cta?.open_link_in_new_tab}
          className={`${mainClassName + '_section_container_button'}`}
          trackEventArgs={{
            category: 'Button',
            action: 'Click',
            name: COMPONENTS_MAP_NAME.DynamicCardSlider,
            value: cta?.label,
          }}
        >
          {cta?.label}
        </Button>
      </div>
    </section>
  )
}

export default DynamicCardsSlider
