import RootLayout from '@/organisms/Layout/RootLayout'
import { init } from '@socialgouv/matomo-next'
import GeneralProvider from 'contexts/General'
import ResponsiveProvider from 'contexts/Responsive'
import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import 'typeface-lora'
import 'typeface-roboto-mono'
import 'typeface-titillium-web'
import '../scss/bootstrap-italia-custom.scss'

const MATOMO_URL = process.env.NEXT_PUBLIC_MATOMO_URL
const MATOMO_SITE_ID = process.env.NEXT_PUBLIC_MATOMO_SITE_ID

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const { header, footer, title } = pageProps
  const pageKey = pageProps?.title || router.asPath

  useEffect(() => {
    init({
      url: MATOMO_URL,
      siteId: MATOMO_SITE_ID,
      disableCookies: true,
    })
  }, [])

  return (
    <GeneralProvider
      options={pageProps.options}
      page={pageProps.additionalData}
    >
      <ResponsiveProvider>
        <AnimatePresence
          initial={false}
          onExitComplete={() => window.scrollTo(0, 0)}
          mode={'wait'}
        >
          <RootLayout
            title={title}
            header={header}
            footer={footer}
          >
            <Component
              key={pageKey}
              {...pageProps}
            />
          </RootLayout>
        </AnimatePresence>
      </ResponsiveProvider>
    </GeneralProvider>
  )
}

export default MyApp
