import { Button, Richtext } from '@/atoms'
import { PARENT_PAGE_BY_CONTENT_TYPE } from 'api/config'
import { useIntersectionObserver } from 'hooks'
import { COMPONENTS_MAP_NAME, withNextLink } from 'utils/functions'

const mainClassName = 'project-card'

const MAX_CHARACTERS = 190

const ProjectCard = ({
  title,
  description,
  cta,
  sectionClassName = '',
  cardOrder = 0,
}) => {
  let formattedDescription = description
  const { ref: cardRef, isIntersecting: isCardVisible } =
    useIntersectionObserver({
      freezeOnceVisible: true,
      threshold: 1,
    })
  const slideBottomUpClassName =
    cardOrder === 0
      ? 'slide-bottom-up fade-in-4 visible'
      : ` fade-in-4 slide-bottom-up-${cardOrder}`

  if (description.length > MAX_CHARACTERS) {
    formattedDescription = description.substring(0, MAX_CHARACTERS) + '...'
  }

  return (
    <section
      ref={cardRef}
      className={`${mainClassName + '_section'} shadow ${
        !isCardVisible ? 'fade-out-4 invisible' : slideBottomUpClassName
      }`}
    >
      <div className={`${mainClassName + '_section_container'}`}>
        <h3 className={`${mainClassName + '_section_container_title'}`}>
          {withNextLink(
            title,
            getCorrectUrl(cta.url, cta.internal_page, cta.internal_url),
            { color: 'inherit' },
            {
              trackEvent: {
                category: 'Button',
                action: 'Click',
                name: `Card ${COMPONENTS_MAP_NAME.ParagraphCard}`,
                value: title,
              },
            },
          )}
        </h3>

        <Richtext
          className={`${mainClassName + '_section_container_description'}`}
          textAlign={'left'}
        >
          {description}
        </Richtext>
        <div className={`${mainClassName + '_section_container_wrapper'}`}>
          <Button
            href={cta?.url}
            variant={'primary'}
            internalUrl={cta?.internal_url}
            isInternalPage={cta?.internal_page}
            openInNewTab={cta?.open_link_in_new_tab}
            className={`${mainClassName + '_section_container_cta'}`}
            trackEventArgs={{
              category: 'Button',
              action: 'Click',
              name: `Card ${COMPONENTS_MAP_NAME.ParagraphCard}`,
              value: cta.label,
            }}
          >
            {cta.label}
          </Button>
        </div>
      </div>
    </section>
  )
}
const getCorrectUrl = (url, isInternalUrl, internalUrl) => {
  const internalUrlObj = internalUrl?.[0]
  if (!internalUrlObj) return url
  let { post_name: slug, post_type: type } = internalUrlObj
  if (slug === 'homepage') slug = ''
  if (type === 'page') type = 'pages'

  /* Relative Path */
  return `${
    type !== 'pages' && PARENT_PAGE_BY_CONTENT_TYPE[type]
      ? PARENT_PAGE_BY_CONTENT_TYPE[type]
      : ''
  }/${slug}`
}

export default ProjectCard
